import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import MainControl from "../layouts/MainControl/MainControl";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

import NotariaReyesUgarte from "../images/Notaria-Reyes-Ugarte.jpg";
import Notario from "../images/Notario.jpg";
import Legalizacion from "../images/Legalizacion-Firmas.jpg";
import FullWidth from "../layouts/FullWidth/FullWidth";

import "./Us.css";
import Loading from "../components/Loading/Loading";
import Mission from "../components/Mission";
import Vision from "../components/Vision";

function Us() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <MainControl custom="bg-us">
      <Helmet>
        <title>Nosotros - Notaría Reyes Ugarte</title>
      </Helmet>

      <Container className="nru-title mt-4" id="nosotros">
        <Row className="text-center Notary">
          <Col md={12}>
            <h2 className="font-weight-bold">Notario</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="mt-3 mt-md-2 mb-4 border-0 center-absolute">
              <Card.Header className="nru-card-header flex-column">
                <div className="card-wrapper-img">
                  <img
                    src={Notario}
                    alt="Notario Carlos Reyes Ugarte"
                    className="img-especial-us"
                    onLoad={() => setLoaded({ loaded: true })}
                  />
                  {!loaded && <Loading text=" " />}
                </div>
                <div className="nru-card-info text-center">
                  <span className="opacity-9">Carlos Alberto Reyes Ugarte</span>
                </div>
              </Card.Header>
              <Col
                md={8}
                className="ml-auto mr-auto text-justify Notary__Content"
              >
                <Card.Body>
                  Estudió primaria y secundaria en la ciudad de Huacho, Abogado
                  titulado en la Universidad Nacional Mayor de San Marcos,
                  Magister en Derecho Civil y Comercial. Ha ejercido cargos de
                  funcionario público en la Municipalidad Provincial del Callao
                  y el Ministerio de Justicia.
                  <br />
                  <br />
                  Ha sido miembro de la Comisión Consultiva de Derecho Comercial
                  del Colegio de Abogados de Lima. Se ha desempeñado como
                  catedrático en la Universidad Nacional José Faustino Sánchez
                  Carrión.
                  <br />
                  <br />
                  Primer lugar en el concurso público de méritos para ejercer la
                  función notarial en la provincia de Huaura en el año 1998.
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
        <FullWidth brand="Us-bg Us-Landing mb-5">
          <div className="d-flex Us-Landing__Wrapper">
            <div className="Us-Landing__Text">
              <div className="center-absolute">
                <h3>Con más de 20 años brindando calidad y servicio</h3>
              </div>
            </div>
            <div className="Us-Landing__Image">
              <Image className="Us-Image-Landing" src={Legalizacion} />
            </div>
          </div>
        </FullWidth>

        <Row>
          <Col md={12}>
            <h2 className="text-center mb-4 h1 font-weight-bold">
              Acerca de la notaría
            </h2>
          </Col>
        </Row>
        <Row className="about-notary mb-5">
          <Col md={7} className="pl-5">
            <p>
              <span className="h2">F</span>undada un 19 de Octubre de 1998,
              hemos ido creciendo a lo largo del tiempo gracias a la confianza
              de nuestros clientes. Nuestro crecimiento se ve reflejado en la
              satisfacción de quienes deciden trabajar con nosotros y en la
              formación constante a nivel profesional y en valores de todo
              nuestro personal.
            </p>
            <p>
              Contamos con ambientes seguros, cómodos, privados y adaptados a
              las diferentes necesidades de cada persona. La notaría está
              diseñada especialmente para garantizar la accesibilidad y la
              ausencia de barreras en la atención de personas con discapacidad y
              adultos mayores, así como para jóvenes emprendedores, estudiantes,
              profesionales, empresas y/o instituciones en general.
            </p>
          </Col>
          <Col md={5} className="Bg-About-Us">
            <div>
              <img
                style={{ objectFit: "cover" }}
                src={NotariaReyesUgarte}
                alt="Notaría Reyes Ugarte - Huacho"
              />
            </div>
          </Col>
        </Row>

        <Row className="values-notary mb-5">
          <Col md={6} className="px-5">
            <Mission />
          </Col>
          <Col md={6} className="px-5">
            <Vision />
          </Col>
        </Row>
      </Container>
    </MainControl>
  );
}

export default Us;
