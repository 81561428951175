import React from "react";
import FullWidth from "../../layouts/FullWidth/FullWidth";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// import "./Landing.css";

const Primary = ({ style }) => (
  <FullWidth style={style} brand="mb-5 text-center">
    <Container>
      <div className="p-5">
        <Row>
          <Col md={6} className="px-0">
            <h3 className="font-weight-bold text-center text-md-left">
              Si tiene alguna duda puede contactarnos para más información
            </h3>
          </Col>
          <Col md={4} className="px-0 ml-auto">
            <div className="h-100 w-100 center-absolute">
              <Link
                to="/contacto"
                className="btn btn-primary btn-lg"
              >
                Contáctanos
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  </FullWidth>
);

const Secondary = ({ style }) => (
  <FullWidth style={style} brand="mb-5 text-center">
    <Container>
      <div className="p-5 center-absolute visit">
        <h4>Visitanos para poder ayudarte</h4>
      </div>
    </Container>
  </FullWidth>
);

const Landing = ({ messageType, style }) => {
  return messageType === "primary" ? (
    <Primary style={style} />
  ) : (
    <Secondary style={style} />
  );
};

Landing.defaultProps = {
  messageType: "primary",
};

export default Landing;
