import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function ButtonWithTooltip(props) {
  const placement = "bottom";

  return (
    <>
      <OverlayTrigger
        trigger="click"
        defaultShow={true}
        placement={placement}
        overlay={
          <Popover id={`popover-positioned-${placement}`}>
            <Popover.Title as="h3">Ampliar formulario</Popover.Title>
          </Popover>
        }
      >
        {props.children}
      </OverlayTrigger>
    </>
  );
}
