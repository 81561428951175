import LevantamientoHipotecas from "../../images/Levantamiento-Hipotecas.jpg";
import testamento from "../../images/Testamento.jpg";
import AdopcionMayores from "../../images/Adopcion-Mayores.jpg";
// import RectificaciónPartidas from "../../images/Rectificacion-Partidas.jpg";
import CartasNotariales from "../../images/Cartas-Notariales.jpg";
// import LegalizaciónFirmas from "../../images/Legalizacion-Firmas.jpg";

const services = [
  {
    id: 1,
    alt: "Levantamiento de Hipotecas",
    title: "Levantamiento de Hipotecas",
    url: LevantamientoHipotecas,
    link: [
      {
        url: "/servicios/protocolares/levantamiento-hipotecas",
        name: "Ver más",
        styles: {},
      },
    ],
  },
  {
    id: 2,
    alt: "Testamentos",
    title: "Testamentos",
    url: testamento,
    link: [
      {
        url: "/servicios/protocolares/testamentos",
        name: "Ver más",
        styles: {},
      },
    ],
  },
  {
    id: 3,
    alt: "Adopción de mayores de edad",
    title: "Adopción de mayores de edad",
    url: AdopcionMayores,
    link: [
      {
        url: "/servicios/asuntos-no-contenciosos/adopcion-mayor-edad",
        name: "Ver más",
        styles: {},
      },
    ],
  },
  // {
  //   id: 4,
  //   alt: "Rectificación de partidas",
  //   title: "Rectificación de partidas",
  //   url: RectificaciónPartidas,
  //   link: [
  //     {
  //       url: "/servicios/asuntos-no-contenciosos/rectificacion-partidas",
  //       name: "Ver más",
  //       styles: {},
  //     },
  //   ],
  // },
  {
    id: 5,
    alt: "Cartas Notariales",
    title: "Cartas Notariales",
    url: CartasNotariales,
    link: [
      {
        url: "/servicios/extra-protocolares/cartas-notariales",
        name: "Ver más",
        styles: {},
      },
    ],
  },
  // {
  //   id: 6,
  //   alt: "Legalización de firmas",
  //   title: "Legalización de firmas",
  //   url: LegalizaciónFirmas,
  //   link: [
  //     {
  //       url: "/servicios/extra-protocolares/legalizacion-firmas",
  //       name: "Ver más",
  //       styles: {},
  //     },
  //   ],
  // },
];

export { services };
