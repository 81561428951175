import React from "react";
import "./fw-styles.css";

const FullWidth = ({ children, brand, style }) => {
  const bgBrand = !brand ? "" : brand.length ? brand : "bg-brand";

  return (
    <>
      <div style={style} className={`${bgBrand} cover full-width`}>
        {children}
      </div>
    </>
  );
};

export default FullWidth;
