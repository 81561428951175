import React, { useState } from "react";
import ReactDOM from "react-dom";

import {
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import Loading from "../../components/Loading/Loading";
import Initialized from "../../components/Config/InitializeApp";
import "./ServiceBeacon.css";
import { withRouter } from "react-router-dom";
import { thereSomeString } from "../../components/Tools/Tools";
import ButtonWithTooltip from "../../components/ButtonWithTooltip/ButtonWithTooltip";

const Info = (props) => {
  const [show, setShow] = useState(true);

  return (
    <Alert
      variant="secondary"
      dismissible
      show={show}
      onClose={() => setShow(false)}
      className={props.className}
    >
      {props.title && <Alert.Heading as="h6">{props.title}</Alert.Heading>}
      {props.content && props.content}
      {props.children && props.children}
    </Alert>
  );
};

class ServiceBeaconContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "",
      titleNumber: "",
      sent: false,
      loading: false,
      notFound: false,
      activeForm: false,
      sentFormData: false,
      fullScreen: false,
      textCopied: false,
      form: {
        nameContact: "",
        kardex: "",
        phone: "",
        email: "",
      },
      defaultMessages: [
        <Info>
          <h5>Simple de utilizar:</h5>
          <div>
            <ol>
              <li>Ingrese su número de kardex.</li>
              <li>Presione click en buscar.</li>
              <li>Listo!</li>
            </ol>
            <strong>Recuerde:</strong>
            <p>Si tiene alguna duda puede escribirnos al WhatsApp Notarial.</p>
          </div>
        </Info>,
      ],
      errorMessages: [],
      successMessages: [],
    };

    this.form = null;
    this.formContact = null;
    this.app = Initialized();
    this.db = this.app.database().ref();
    this.ref = React.createRef();
    this.copiedId = null;

    this.handlerStopPropagation = this.handlerStopPropagation.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.activeForm = this.activeForm.bind(this);
    this.changeFullScreen = this.changeFullScreen.bind(this);
    this.changeFormData = this.changeFormData.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.sentFormData = this.sentFormData.bind(this);
    this.copyText = this.copyText.bind(this);
    this.copied = this.copied.bind(this);
  }

  handlerStopPropagation(e) {
    e.stopPropagation();
  }

  activeForm() {
    this.form = setTimeout(() => {
      this.setState({
        activeForm: true,
      });
    }, 1500);
  }

  sentFormData() {
    this.formContact = setTimeout(() => {
      this.setState({
        sentFormData: false,
        sent: false,
        notFound: false, //Regreso al estado inicial
      });
    }, 2000);
  }

  handlerSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      successMessages: [],
      errorMessages: [],
      sent: false,
      notFound: false,
      activeForm: false,
    });
    /* Reseteando los mensajes para mostrar nuevos. */

    let answer = this.db
      .child("records")
      .orderByChild("kardex")
      .equalTo(this.state.key);

    answer.once("value", (values) => {
      //Comprobar si funciona con "on"
      if (values.val() === null) {
        this.setState({
          sent: true,
          loading: false,
          notFound: true,
        });
        // No hay mensajes de error por ahora.
        this.activeForm();

        return false;
      }

      let keys = Object.keys(values.val()),
        tn = values.val()[keys[0]].titleNumber;
      // No hay mensajes de satisfactorio por ahora.
      this.setState(({ successMessages }) => {
        return {
          sent: true,
          titleNumber: tn,
          loading: false,
          successMessages: [
            ...successMessages,
            <Info>
              <h4 style={{ fontSize: "1.4rem" }}>Siga los siguientes pasos:</h4>
              <div>
                <ol>
                  <li>
                    Copie el número de título brindado en la parte superior.
                  </li>
                  <li>
                    Presione click en la imagen de "Síguelo" para poder revisar
                    su estado.
                  </li>
                  <li>Listo!</li>
                </ol>
                <strong>Recuerde:</strong>
                <p>
                  La página de Sunarp le solicitará su Oficina Registral y el
                  Año de título. <br />
                  <span style={{ fontStyle: "oblique" }}>
                    Asegúrese de tenerlos a la mano.
                  </span>
                </p>
              </div>
            </Info>,
          ],
        };
      });
    });
  }

  handlerChange(e) {
    if (!thereSomeString(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.form);
    window.clearTimeout(this.formContact);
    window.clearTimeout(this.copiedId);
    this.db.off();
  }

  /* <===========Formulario de contacto============> */
  changeFullScreen() {
    this.setState({ fullScreen: !this.state.fullScreen });
  }

  changeFormData(e) {
    const { name, value } = e.target;
    this.setState(({ form }) => {
      return {
        form: {
          ...form,
          [name]: value,
        },
      };
    });
  }

  submitFormData(e) {
    e.preventDefault();
    this.setState(
      ({ form, key }) => {
        return {
          form: { ...form, kardex: key },
        };
      },
      () => {
        this.db
          .child("peopleToContact")
          .push()
          .set({
            ...this.state.form,
          })
          .then((res) => {
            this.setState({
              sentFormData: true,
              activeForm: false,
            });

            this.sentFormData();
          });
      }
    );
  }

  copyText() {
    navigator.clipboard.writeText(this.ref.current.textContent).then(() => {
      this.setState({ textCopied: true });
      this.copied();
    });
  }

  copied() {
    this.copiedId = window.setTimeout(() => {
      this.setState({ textCopied: false });
    }, 1200);
  }

  render() {
    const InfoResult = (
      <Card.Body bg="light" className="text-center Card-Online degradate">
        <Card.Title>¡Muchas gracias por usar nuestros servicios!</Card.Title>
        <div className="d-flex justify-content-center align-items-center">
          Número de Título:
          <div
            className="text-prevent ml-2"
            ref={this.ref}
            onClick={this.copyText}
          >
            {this.state.titleNumber}
          </div>
          <Overlay
            target={this.ref.current}
            show={this.state.textCopied}
            placement="right"
          >
            {(props) => (
              <Tooltip id="Tooltip-Copy" {...props}>
                ¡Copiado!
              </Tooltip>
            )}
          </Overlay>
        </div>
        <br />
        <div className="position-relative d-block">
          <div
            className="btn-sunarp-siguelo"
            onClick={() => {
              window.open(
                "https://siguelo.sunarp.gob.pe/siguelo/formulario/login.html"
              );
            }}
          ></div>
        </div>
      </Card.Body>
    );

    const TextError = (
      <Card.Body bg="light" className="text-center Card-Online degradate">
        <Card.Title>
          No encontramos tu número de título <i className="icon-emo-unhappy" />{" "}
        </Card.Title>
        <div className="d-flex justify-content-center align-items-center">
          Revisa tu número ingresado o intenta en unas horas.
        </div>
        <br />
        <div className="d-flex justify-content-center align-items-center font-weight-bold">
          ¡Gracias por usar nuestros servicios!
        </div>
        <i className="icon-down-big d-block w-100" />
        <span>
          <small>
            Te avisamos cuando tengamos un nuevo estado para tu trámite.
          </small>
        </span>
      </Card.Body>
    );

    const InfoDefault = (
      <Card.Body bg="light" className="text-center Card-Online">
        <Card.Title>
          Ingrese su número de kardex <i className="icon-up-big" /> y verifique
          el estado de su trámite por internet.
        </Card.Title>
      </Card.Body>
    );

    /* <===========Formulario de contacto============> */
    const FormContact = (
      <Info className={`${this.state.fullScreen ? "Full-Screen" : ""}`}>
        <ButtonWithTooltip>
          <button
            onClick={this.changeFullScreen}
            className="Full-Screen__Button"
          >
            <i className="icon-resize-full" />
          </button>
        </ButtonWithTooltip>
        <h6 className="font-weight-bold text-uppercase text-center">
          Déjanos tus datos de contacto
        </h6>
        <Form onChange={this.changeFormData} onSubmit={this.submitFormData}>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nombre: </Form.Label>
                <Form.Control name="nameContact" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email: </Form.Label>
                <Form.Control name="email" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Telefono: </Form.Label>
                <Form.Control name="phone" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>kardex: </Form.Label>
                <Form.Control disabled defaultValue={this.state.key} />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Button type="submit" className="w-100">
                Enviar
              </Button>
            </Col>
          </Row>
          {/* Podría poner un mensaje de alerta, ejem: Debe llenar todos los campos. */}
        </Form>
        <span>
          <small>
            <strong>Recuerde: </strong>
            Con estos datos usted será notificado cuando exista un estado de su
            trámite.
          </small>
        </span>
      </Info>
    );

    const ThankForSendData = (
      <Info className="center-absolute w-100 h-100">
        <div>Gracias por usar nuestros servicios!</div>
      </Info>
    );

    return (
      <div className="Super-Cover" onClick={this.props.handlerClose}>
        <Card
          className="Service-Beacon my-2 my-md-0 slideExpandUp"
          onClick={this.handlerStopPropagation}
        >
          <Card.Header className="Service-Beacon__Header">
            <Form
              inline
              className="flex-flow-unset"
              onSubmit={this.handlerSubmit}
            >
              <Form.Control
                placeholder="Ingresa tu número de kardex"
                className="flex-grow-1"
                name="key"
                value={this.state.key}
                onChange={this.handlerChange}
              />
              <Button className="ml-2" type="submit">
                Buscar
              </Button>
            </Form>
          </Card.Header>
          <Card.Body className="Service-Beacon__Body">
            {this.state.loading && <Loading text="procesando..." />}
            {this.state.notFound
              ? TextError
              : this.state.sent
              ? InfoResult
              : InfoDefault}
          </Card.Body>
          <Card.Footer className="Service-Beacon__Footer">
            {this.state.activeForm && !this.state.sentFormData && FormContact}
            {this.state.sentFormData && ThankForSendData}

            {this.state.defaultMessages &&
              !this.state.sent &&
              this.state.defaultMessages.map((obj, index) => (
                <div key={`defaultMessages-${index}`}>{obj}</div>
              ))}

            {this.state.successMessages &&
              this.state.successMessages.map((obj, index) => (
                <div key={`successMessages-${index}`}>{obj}</div>
              ))}
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

class ServiceBeacon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      isVisibleIndicator: true,
    };

    this.handlerClose = this.handlerClose.bind(this);
    this.handlerToogle = this.handlerToogle.bind(this);
  }

  handlerClose() {
    this.setState({ show: false });
  }

  handlerToogle() {
    this.setState(({ show }) => {
      return { show: !show, isVisibleIndicator: false };
    });
  }

  render() {
    return ReactDOM.createPortal(
      <>
        {this.state.show && (
          <ServiceBeaconContent handlerClose={this.handlerClose} />
        )}
        <div className="Beacon-so" onClick={this.handlerToogle}>
          <div
            className={`Beacon-so__link no-underline Beacon-btn ${
              this.state.show ? "_close" : ""
            }`}
          >
            <i />
          </div>
        </div>
      </>,
      document.getElementById("__serviceBeacon")
    );
  }
}

export default withRouter(ServiceBeacon);
