import React, { useState, createRef } from "react";
import ReactDOM from "react-dom";
import "./BeaconContainer.css";
import Logo from "../../images/Logo-Notaria-Reyes.png";

class BeaconContainerContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      isFocus: false
    };
    this.txtRef = createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.message.length > 0) {
      window.open(
        "https://api.whatsapp.com/send?phone=51992714468&text=" +
          this.state.message
      );
      this.txtRef.current.value = "";
      this.props.setVisible(false);
    }
    return false;
  }

  handleChange() {
    this.setState({
      message: this.txtRef.current.value
    });
  }

  componentDidUpdate() {
    if (this.props.visible) {
      this.txtRef.current.focus();
    }
  }

  render() {
    return (
      <>
        {this.props.visible && (
          <div className="container BeaconContainerContent slide-top ">
            <div className="px-0 BeaconContainerContent__Wrapper">
              <div className="BeaconContainerContent__Header">
                <div className="B-left">
                  <img src={Logo} alt="Logo Notaría Reyes Ugarte" />
                </div>
                <div className="B-center">
                  <div>
                    <strong>Notaría Reyes Ugarte</strong>
                    <span>En línea</span>
                  </div>
                </div>
                <div className="B-right">
                  <i
                    className="icon-cancel"
                    onClick={() => this.props.setVisible(!this.props.visible)}
                  ></i>
                </div>
              </div>
              <div className="BeaconContainerContent__Body"></div>
              <div className="BeaconContainerContent__Footer">
                <form onSubmit={this.handleSubmit}>
                  <textarea
                    ref={this.txtRef}
                    onChange={this.handleChange}
                    cols="40"
                    rows="1"
                    placeholder="Escribe un mensaje aquí"
                    defaultValue={this.state.message}
                  ></textarea>
                  <button type="submit">
                    <i className="icon-paper-plane" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const BeaconContainer = props => {
  const [visible, setVisible] = useState(false);

  return ReactDOM.createPortal(
    <>
      {<BeaconContainerContent visible={visible} setVisible={setVisible} />}
      <div className="Beacon" onClick={() => setVisible(!visible)}>
        <div className="Beacon__link no-underline">
          <i
            className={`Beacon__item ${
              !visible
                ? "icon-whatsapp show-from-left"
                : "icon-cancel show-from-right"
            } `}
          />
        </div>
      </div>
    </>,
    document.getElementById("__beaconContainer")
  );
};

export default BeaconContainer;
