import React from "react";
import Menu from "../../components/Menu/Menu";
import { Row, Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import FullWidth from "../FullWidth/FullWidth";
import BeaconContainer from "../BeaconContainer/BeaconContainer";
import ServiceBeacon from "../ServiceBeacon/ServiceBeacon";
import "./MainControl.css";
import getPhoneNumbers from "../../components/functions/getPhoneNumbers";

const MainControl = ({ children, custom }) => {
  const phone = getPhoneNumbers(992714468);

  return (
    <>
      <Menu custom={custom} />

      {children}

      <FullWidth>
        <footer className="Footer">
          <Container className="px-5 pt-5 mt-4">
            <Row>
              <Col className="Footer__Section" md={3}>
                <div className="Footer__Title">
                  <h4>¿Quienes somos?</h4>
                </div>
                <div className="Footer__Subtitle">
                  <h6>Notaría Reyes Ugarte</h6>
                </div>
                <div className="Footer__Content">
                  Nuestro crecimiento se ve reflejado en la satisfacción de
                  quienes deciden trabajar con nosotros y en la formación
                  constante a nivel profesional y en valores de todo nuestro
                  personal.
                </div>
              </Col>
              <Col className="Footer__Section" md={3}>
                <div className="Footer__Title mt-2 mt-md-0">
                  <h4>Dirección</h4>
                </div>
                <div className="Footer__Content">
                  Estamos ubicados en la ciudad hospitalaria de Huacho, a una cuadra de la Plaza de Armas.
                  <br />
                  Av. Grau N° 501 - Huacho
                </div>
              </Col>
              <Col className="Footer__Section" md={3}>
                <div className="Footer__Title mt-2 mt-md-0">
                  <h4>Enlaces</h4>
                </div>
                <div className="Footer__Link">
                  <ul className="Footer__Menu">
                    <li className="Footer__Menu-Item">
                      <Link to="/nosotros" className="Footer__Menu-Link">
                        ¿Quienes somos?
                      </Link>
                    </li>
                    <li className="Footer__Menu-Item">
                      <Link to="contacto" className="Footer__Menu-Link">
                        Contáctanos
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col className="Footer__Section" md={3}>
                <div className="Footer__Title">
                  <h4>Contáctanos</h4>
                </div>
                <div className="Footer__Subtitle">
                  <h6>Email</h6>
                </div>
                <div className="Footer__Menu-Link d-flex flex-column">
                  <a href="mailto:recepcion@notariareyes.pe">
                    recepcion@notariareyes.pe
                  </a>
                  <a href="mailto:notareyes@hotmail.com">
                    notareyes@hotmail.com
                  </a>
                </div>
                <div className="Footer__Subtitle mt-3">
                  <h6>Telefonos</h6>
                </div>
                <div className="Footer__Menu-Link">
                  <a href={`tel:${phone}`}>Cel: (+51) 992 714 468</a>
                </div>
                <div className="Footer__Subtitle mt-3">
                  <h6>Redes Sociales</h6>
                </div>
                <div className="pr-4">
                  <div className="Footer__Menu-Link">
                    <div className="d-flex align-items-center justify-content-between">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://web.facebook.com/Notaria-Reyes-Ugarte-100474851668172"
                      >
                        Facebook
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="Footer__Copy">
                  Copyright © Todos los derechos reservados
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </FullWidth>

      <ServiceBeacon />
      <BeaconContainer />
    </>
  );
};

export default MainControl;
