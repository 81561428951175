import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./icons/fontello/css/fontello.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";


ReactDOM.render(<App />, document.getElementById("__next"));

serviceWorker.unregister();
