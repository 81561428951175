import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import MainControl from "../layouts/MainControl/MainControl";
import MapContainer from "../components/MapContainer/MapContainer";
import LandingServiceOnline from "../components/Landing/Landing";

import "./Contact.css";
import getPhoneNumbers from "../components/functions/getPhoneNumbers";

const Contact = () => {
  const phone = getPhoneNumbers(992714468);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainControl>
      <Helmet>
        <title>Contacto - Notaría Reyes Ugarte</title>
      </Helmet>

      <Container className="mt-5 mb-5" id="contacto">
        <h2 className="text-center mb-4 mb-md-5 font-weight-bold">
          Contáctenos
        </h2>
        <Row>
          <Col md={11} className="mx-auto mb-5">
            <Row>
              <Col className="mb-3 px-5 px-md-4 text-center" md={3}>
                <i className="icon-chat Contact-Icon" />
                <h5 className="Contact-title">Nuestras redes</h5>
                <hr className="custom-bb" />
                <a
                  className=" Contact-Data font-weight-normal"
                  href="https://web.facebook.com/Notaria-Reyes-Ugarte-100474851668172"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="icon-facebook social-network__item color-initial"
                    style={{ fontSize: "inherit", color: "#0C86EF " }}
                  />
                  Facebook
                </a>
              </Col>
              <Col className="mb-3 px-5 px-md-4 text-center" md={3}>
                <i className="icon-mail Contact-Icon" />
                <h5 className="Contact-title">Email</h5>
                <hr className="custom-bb" />
                <div className="d-flex flex-column">
                  <a href="mailto:recepcion@notariareyes.pe">
                    recepcion@notariareyes.pe
                  </a>
                  <a href="mailto:notareyes@hotmail.com">
                    notareyes@hotmail.com
                  </a>
                </div>
              </Col>
              <Col className="mb-3 px-5 px-md-4 text-center" md={3}>
                <i className="icon-direction Contact-Icon" />
                <h5 className="Contact-title">Dirección</h5>
                <hr className="custom-bb" />
                <span>AV. GRAU N° 501 - HUACHO</span>
              </Col>
              <Col className="mb-3 px-5 px-md-4 text-center" md={3}>
                <i className="icon-phone Contact-Icon" />
                <h5 className="Contact-title">Números telefónicos</h5>
                <hr className="custom-bb" />

                <a className="d-block" href={`tel:${phone}`}>
                  Cel: (+51) 992 714 468
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="contact-us nru-title">
          <h5 className="ml-3 mb-2 h4">Puedes ubicarnos en:</h5>
          <Col md={12}>
            <div className="few-details-with-border">
              <MapContainer />
            </div>
          </Col>
        </Row>
      </Container>

      <LandingServiceOnline messageType="secondary" />
    </MainControl>
  );
};

export default Contact;
