import React from "react";
import "./ButtonNRU.css";
import { Link } from "react-router-dom";

const ButtonNRU = ({ children, className, to, external }) => {
  return (
    <>
      {external ? (
        <a
          href={to}
          className={`ButtonNRU ${className}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
          <span className="ButtonNRU__before"></span>
          <span className="ButtonNRU__after"></span>
        </a>
      ) : (
        <Link to={to} className={`ButtonNRU ${className}`}>
          {children}
          <span className="ButtonNRU__before"></span>
          <span className="ButtonNRU__after"></span>
        </Link>
      )}
    </>
  );
};

export { ButtonNRU };
