import React from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import { Icon } from "leaflet";

const url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

const position = [-11.108135, -77.609095];
const zoom = 18;
const iconMarket = new Icon({
  iconUrl: icon,
  iconRetinaUrl: iconRetina,
  shadowUrl: iconShadow,
});

class MapContainer extends React.Component {
  state = {
    active: null,
  };
  render() {
    return (
      <>
        <Map
          center={position}
          zoom={zoom}
          style={{
            height: "50vh",
            width: "100%",
            overflow: "hidden",
            zIndex: "-1",
          }}
        >
          <TileLayer url={url} attribution={attribution} />
          <Marker position={position} icon={iconMarket}>
            <Popup>
              <h4>
                <strong style={{ color: "#009846" }}>
                  Notaria Reyes Ugarte
                </strong>
              </h4>
              <hr className="mt-0 mb-1" />
              <span>
                <big>
                  Estamos ubicados en <strong>Huacho</strong>, a una cuadra de
                  la plaza de armas.
                </big>
              </span>
              <hr className="mt-1 mb-0" />
              <span>
                <big className="d-block text-center">
                  Jose Ausejo Salas - 101
                </big>
              </span>
            </Popup>
          </Marker>
        </Map>
      </>
    );
  }
}

export default MapContainer;
