import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = (props) => {
  return (
    <div className="loading center-absolute">
      <div className="d-flex flex-column align-items-center">
        <div>
          <Spinner animation="border" variant="dark" />
        </div>
        <span className="mt-2">{props.text}</span>
      </div>
    </div>
  );
};

Loading.defaultProps = {
  text: "Cargando",
  error: false,
};

export default Loading;
