import React from "react";

import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ButtonEspecial from "../ButtonEspecial/ButtonEspecial";
import LogoNotariaReyesUgarte from "../../images/Logo-Notaria-Reyes.jpg";
import "./m-styles.css";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
      lastScrollTop: 0,
      route: "/",
    };

    this.toggleHeader = this.toggleHeader.bind(this);
    this.getFirstRoute = this.getFirstRoute.bind(this);
  }

  toggleHeader() {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (this.state.lastScrollTop >= scrollTop) {
      if (this.state.hide) this.setState({ hide: false });
    } else {
      if (!this.state.hide) this.setState({ hide: true });
    }

    /* Al iniciar no pasa nada raro, pero cuando valla a otra página y vuelvo al inicio
      este componente se renderiza y como los otros componentes no terminan de renderizarse 
      generan un scroll lo cual hacia que se ocultase este componente con la clase Hide
      para evitar esto le indicamos cuando lastScrollTop(0) sea mayor o igual a el scrollTop
      le damos hide.
      Que cuando sea mayor el lastScrollTop osea cuando se esté subiendo y pero este es el factor clave
      como al regresar se genera un pequeño scroll con esta condicion evitamos que se oculte como
      antes ocurría. El igual para cuando se entre por primera vez a la app.
    */

    this.setState({ lastScrollTop: scrollTop });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleHeader);
    this.setState({
      route: this.getFirstRoute(this.props.location.pathname),
    });
  }

  getFirstRoute(route) {
    return route.length > 1
      ? route
          .slice(
            0,
            route.indexOf("/", 1) !== -1 ? route.indexOf("/", 1) : route.length
          )
          .trim()
      : route.slice(0).trim();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleHeader);
  }

  render() {
    return (
      <>
        <Navbar
          expand="md"
          className={`Main-menu bg-white px-2 px-md-3 ${
            this.state.hide ? "Hide" : ""
          }`}
        >
          <Container className="pt-2 mx-md-3 mw-none mx-xl-auto">
            <div className="CoverLogo"></div>
            <Link
              to="/"
              className="navbar-brand text-uppercase Nav-Bar-Extra mr-1 mr-md-3"
            >
              <Image
                className="mr-2"
                style={{ borderRadius: "50%", boxShadow: "0 0.5px 5px 1px #fff" }}
                src={LogoNotariaReyesUgarte}
                alt="Logo de Notaria Reyes Ugarte - Huacho"
              />{" "}
              Notaría <span className="Font-NRU">Reyes Ugarte</span>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto position-relative Nav-Extra">
                <Link
                  to="/"
                  className={`navbar-item nav-link ${
                    this.state.route === "/" ? "link-active" : ""
                  }`}
                >
                  Inicio
                </Link>

                <Link
                  to="/nosotros"
                  className={`navbar-item nav-link ${
                    this.state.route === "/nosotros" ? "link-active" : ""
                  }`}
                >
                  Quienes somos
                </Link>

                <Link
                  to="/contacto"
                  className={`navbar-item nav-link ${
                    this.state.route === "/contacto" ? "link-active" : ""
                  }`}
                >
                  Contáctenos
                </Link>

                <ButtonEspecial />

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withRouter(Menu);
