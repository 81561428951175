import * as firebase from "firebase/app";
import { DB_CONFIG } from "../Config/Config";

const InitializedApp = () => {

    if (!firebase.apps.length) {
        /* firebase.analytics(); */
        return firebase.initializeApp(DB_CONFIG);
    }else {
        return firebase;
    }
}

export default InitializedApp;
