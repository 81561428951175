import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./containers/Home";
import Us from "./containers/Us";
import Contact from "./containers/Contact";

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/contacto" component={Contact} />
                <Route exact path="/nosotros" component={Us} />
                <Route exact path="/" component={Home} />
            </Switch>
        </BrowserRouter>
    );
}