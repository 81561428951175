import React from "react";

export default function Vision() {
  return (
    <div>
      <h2 className="text-center mb-4 font-weight-bold">Visión</h2>
      <div style={{ fontSize: "1.2em" }}>
        <p>
          Elevar nuestro reconocimiento como una Notaría líder a nivel nacional
          dotado de eficiencia y ética profesional, con un grupo profesional
          interesado en brindar la mejor asesoría y servicio.
        </p>
      </div>
    </div>
  );
}
