import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

import MainControl from "../layouts/MainControl/MainControl";
import FullWidth from "../layouts/FullWidth/FullWidth";
import LandingServiceOnline from "../components/Landing/Landing";
import Banner from "../layouts/Banner/Banner";

import { services as _storeServices } from "../components/Store/StoreOnlyRead";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: _storeServices,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MainControl>
        <Helmet>
          <title>
            Servicios Notariales | Consulta en Línea | Notaría Reyes Ugarte
          </title>
        </Helmet>

        <FullWidth>
          <Banner />
        </FullWidth>

        <Container>
          <LandingServiceOnline
            style={{ backgroundColor: "beige", marginTop: "3rem" }}
          />
        </Container>
      </MainControl>
    );
  }
}

export default Home;
