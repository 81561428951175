import React from "react";

import WithLogState from "../LogState/LogState";
import "./ButtonEspecial.css";

const BtnEspecialSecondary = () => (
  <a href="/gestor-contenido" className="navbar-authorized nav-link icon-user">
    Gestor de contenido
  </a>
);

const BtnEspecialPrimary = () => (
  <a href="/panel-control" className="navbar-authorized nav-link icon-user">
    Panel de control
  </a>
);

const ButtonEspecial = (props) => {
  return props.userState.loggedIn ? (
    <div className="wrapper-auth ">
      <BtnEspecialPrimary /> <BtnEspecialSecondary />
    </div>
  ) : (
    null
  );
};

export default WithLogState(ButtonEspecial);
