import React from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";
import "./B-styles.css";
import Initialized from "../../components/Config/InitializeApp";
import "firebase/database";
import Loading from "../../components/Loading/Loading";
import { ButtonNRU } from "../../components/ButtonNRU/ButtonNRU";
import { Fade, Zoom } from "react-reveal";

class BannerItemCover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexFirstWordTitle: null,
    };
  }

  componentDidMount() {
    const { title } = this.props;
    this.setState({ indexFirstWordTitle: title.indexOf(" ") });
  }

  render() {
    const { button, image, title, description, className } = this.props;
    return (
      <Carousel.Item className={`h-100 w-100 Banner-smooth-cut ${className}`}>
        <div className="Banner-Item-Cover">
          <Container className="w-100 h-100">
            <Row className="w-100 h-100 margins">
              <Col
                md={5}
                xl={4}
                className="z-md-auto position-static d-flex justify-content-center align-items-center"
              >
                <Image
                  src={image.url}
                  alt={image.alt}
                  className="h-100 w-100 image-nice"
                />
                <div className="Banner-Group z-normal">
                  <div className="Banner-Brand">Reyes Ugarte</div>
                  <div className="Banner-Title">
                    <Zoom>
                      <h1 className="Banner-Market position-relative">
                        <span className="Banner-Title__retouch">
                          {title.slice(0, this.state.indexFirstWordTitle)}
                        </span>{" "}
                        {title.slice(this.state.indexFirstWordTitle + 1)}
                      </h1>
                    </Zoom>
                  </div>

                  {description && (
                    <p className="Banner-Descripcion">{description}</p>
                  )}

                  <Fade left delay={1000}>
                    <div className="Banner-Link">
                      <ButtonNRU
                        className="btn text-white border-0"
                        to={button.route}
                        external={button.linkType !== "interno"}
                      >
                        {button.name}
                      </ButtonNRU>
                    </div>
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Carousel.Item>
    );
  }
}

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoSliders: [],
      loaded: false,
    };
    this.app = Initialized();
    this.db = this.app.database().ref();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    this.db.child("infoSliders").on("child_added", (snap) => {
      if (this._isMounted) {
        this.setState((state) => {
          return {
            infoSliders: [...state.infoSliders, { ...snap.val() }],
            loaded: true,
          };
        });
      }
    });
  }

  componentWillUnmount() {
    this.db.off();
    this._isMounted = false;
  }

  render() {
    const _infoSliders = this.state.infoSliders.filter((i) => i.image.url);

    return (
      <div className="mb-5 Banner-Container-Extra">
        {!this.state.loaded ? (
          <Loading text="Cargando sliders" />
        ) : (
          <Carousel
            indicators={true}
            pause="hover"
            controls={false}
            keyboard={true}
            className="h-100"
          >
            {_infoSliders.reverse().map((slider, index) => (
              <BannerItemCover
                key={index}
                title={slider.title}
                description={slider.description}
                image={slider.image}
                button={slider.button}
              />
            ))}
          </Carousel>
        )}
      </div>
    );
  }
}

export default Banner;
