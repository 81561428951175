import React from "react";
import Initialized from "../Config/InitializeApp";
import Loading from "../Loading/Loading";

const WithLogState = (WrapperComponent) => {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        loaded: false,
        onLine: true,
        userState: {
          loggedIn: false,
        },
      };

      this.app = Initialized();
      this.unsubscribe = null;
      this.idTimeOut = null;
      this.handleOffSession = this.handleOffSession.bind(this);
    }

    handleOffSession() {
      return this.app.auth().signOut();
    }

    componentDidMount() {
      try {
        this.unsubscribe = this.app.auth().onAuthStateChanged(
          function (user) {
            if (this.state.loaded) this.setState({ loaded: false });
            if (user) {
              this.setState({ userState: { loggedIn: true }, loaded: true });
            } else {
              this.setState({ userState: { loggedIn: false }, loaded: true });
            }
          }.bind(this)
        );
      } catch (error) {
        if (!window.navigator.onLine) {
          console.error("Sin acceso a internet");
          this.setState({ onLine: false });
        } else {
          console.error("Ocurrió un error inesperado");
        }
        this.setState({ loaded: true });
      }
    }

    componentWillUnmount() {
      if (this.unsubscribe) this.unsubscribe();   
    }

    render() {
      return this.state.loaded ? (
        <WrapperComponent
          offSession={this.handleOffSession}
          userState={this.state.userState}
          onLine={this.state.onLine}
          {...this.props}
        />
      ) : (
        <Loading
          text={this.state.onLine ? "Cargando" : "No hay acceso a internet"}
          error={!this.state.onLine}
        />
      );
    }
  };

  hoc.displayName = `WithLogState(${
    WrapperComponent.displayName || WrapperComponent.name
  })`;

  return hoc;
};

export default WithLogState;
