import React from "react";

export default function Mission() {
  return (
    <div>
      <h2 className="text-center mb-4 font-weight-bold">Misión</h2>
      <div style={{ fontSize: "1.2em" }}>
        <p>
          Brindar servicios notariales con personal capacitado, logrando que su
          gestión sea un éxito, promoviendo un servicio eficiente, moderno y
          ágil; sustentado en los principios éticos y morales en los que se
          fundan la honorable función notarial.
        </p>
      </div>
    </div>
  );
}
